export const uiStore = {
  namespaced: true,
  state: {
    sidebar: false,
    microcart: false,
    totalsLoading: false,
    searchpanel: false,
    accountPanel: false,
    currencySwitcher: false,
    newsletterPopup: false,
    mobileFilterBy: false,
    overlay: false,
    loader: false,
    authElem: 'login',
    checkoutMode: false,
    openMyAccount: false,
    submenu: {
      depth: false,
      path: []
    },
    wishlist: false,
    isMobile: false,
    searchSuggestions: false,
    checkoutSection: {
      shipping: true,
      payment: false,
      orderReview: false
    },
    loginModalTitle: 'Log in',
    /* Featured categories */
    featuredCategories: [],
    /* Megamenu */
    megamenu: false,
    megamenuEnterIt: null,
    megamenuLeaveIt: null,
    megamenuHover: false,
    megamenuEnabled: true
  },
  mutations: {
    setCheckoutMode (state, action) {
      state.checkoutMode = action === true
    },
    setMicrocart (state, action) {
      state.microcart = action === true
      state.overlay = action === true
    },
    setSidebar (state, action) {
      state.sidebar = action === true
      state.overlay = action === true
    },
    setTotalsLoading (state, status) {
      state.totalsLoading = status
    },
    setMobileFilterBy (state, action) {
      state.mobileFilterBy = action === true
      state.overlay = action === true
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.pop()
        state.submenu.path.push(id)
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop()
        }, 300)
      }
      state.submenu.depth = state.submenu.depth > 0 && depth
    },
    setSearchpanel (state, action) {
      state.searchpanel = action === true
      state.overlay = action === true
    },
    setAccountPanel (state, action) {
      state.accountPanel = action === true
      state.overlay = action === true
    },
    setCurrencySwitcher (state, action) {
      state.currencySwitcher = action === true
      state.overlay = action === true
    },
    setOverlay (state, action) {
      state.overlay = action === true
    },
    setLoader (state, action) {
      state.loader = action === true
    },
    setAuthElem (state, action) {
      state.authElem = action
    },
    setWishlist (state, action) {
      state.wishlist = action === true
      state.overlay = action === true
    },
    setIsMobile (state, action) {
      state.isMobile = action === true
    },
    setFeaturedCategories (state, categories) {
      state.featuredCategories = categories || []
    },
    setMegaMenu (state, action) {
      state.megamenu = action;
    },
    setMegaMenuHover (state, action) {
      state.megamenuHover = action === true;
    },
    setMegaMenuEnabled (state, action) {
      state.megamenuEnabled = action === true
    },
    setSearchSuggestions (state, action) {
      state.searchSuggestions = action === true
    },
    setLoginModalTitle (state, action) {
      state.loginModalTitle = action
    }
  },
  actions: {
    toggleMicrocart ({ commit, state }) {
      commit('setMicrocart', !state.microcart)
    },
    toggleWishlist ({ commit, state }) {
      commit('setWishlist', !state.wishlist)
    },
    closeMicrocart ({ commit, state }) {
      if (state.microcart) commit('setMicrocart', false)
    },
    setTotalsLoading ({ commit, state }, status) {
      commit('setTotalsLoading', status)
    },
    toggleMobileFilterBy ({ commit, state }) {
      commit('setMobileFilterBy', !state.mobileFilterBy)
    },
    async loadFeaturedCategories ({ commit, dispatch, state, getters }, id) {
      const categories = getters.getFeaturedCategories

      if (categories.length) {
        return
      }

      let res = await dispatch('category-next/findCategories', {
        parentId: id,
        filters: {
          is_featured: true
        },
        includeFields: ['id', 'name', 'product_count', 'is_featured']
      }, { root: true })
      res = res?.filter(cat => { return cat.product_count > 0 })

      commit('setFeaturedCategories', res)
    },
    setMegaMenuCategory ({ commit, state }, category) {
      clearInterval(state.megamenuEnterIt)
      state.megamenuEnterIt = setTimeout(() => {
        commit('setMegaMenu', category)
      }, 200)
    },
    mouseoverMegaMenu ({ commit, state }) {
      clearInterval(state.megamenuLeaveIt)
      commit('setMegaMenuHover', true)
    },
    mouseleaveMegaMenu ({ commit, state }) {
      clearInterval(state.megamenuEnterIt)
      state.megamenuLeaveIt = setTimeout(() => {
        commit('setMegaMenuHover', false)
      }, 500)
    },
    changeLoginModalTitle ({ commit, state }, title) {
      commit('setLoginModalTitle', title)
    }
  },
  getters: {
    isMobile (state) {
      return state.isMobile
    },
    isTotalsLoading (state) {
      return state.totalsLoading
    },
    getMobileFiltersVisibility (state) {
      return state.mobileFilterBy
    },
    getFeaturedCategories (state) {
      return state.featuredCategories
    },
    getMegaMenuVisibility (state) {
      return state.megamenuHover && state.megamenuEnabled && state.megamenu;
    },
    getCheckoutSection (state) {
      return state.checkoutSection
    },
    getLoginModalTitle (state) {
      return state.loginModalTitle
    }
  }
}
