import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { SearchSuggestionsState } from '../types/searchSuggestionsState'
import { SET_SEARCH_SUGGESTIONS } from './mutation-types';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { createLoadSynonymsQuery } from '../helpers'

const actions: ActionTree<SearchSuggestionsState, RootState> = {
  setSearchSuggestions ({ commit }, suggestions) {
    const fields = ['description', 'name_with_brand']
    let suggestedStrings = []
    fields.forEach(field => {
      if (suggestions[field] && Array.isArray(suggestions[field][0].options) && suggestions[field][0].options.length > 0) {
        suggestions[field][0].options.forEach(
          el => suggestedStrings.indexOf(el.text) === -1 ? suggestedStrings.push(el.text) : null
        );
      }
    })
    commit(SET_SEARCH_SUGGESTIONS, suggestedStrings)
  },
  async fetchSynonyms ({ commit }, {
    searchString,
    start = 0,
    size = 50,
    entityType = 'search_synonyms',
    excludeFields = null,
    includeFields = ['id', 'synonyms']
  }) {
    const query = createLoadSynonymsQuery({ searchString })

    return quickSearchByQuery({ query, start, size, entityType, excludeFields, includeFields })
  }
}

export default actions
