import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'

const PRODUCTS_LIMIT = 8
const QUERY_OPTIONS = {
  populateRequestCacheTags: false,
  prefetchGroupProducts: false,
  assignProductConfiguration: false
}

export const featuredProductsStore = {
  namespaced: true,
  state: {
    productsByCategory: {}, // Store products indexed by category ID
    currentCategoryId: null
  },

  actions: {
    async loadFeaturedProducts({ commit, dispatch, state }, categoryId) {
      if (state.productsByCategory[categoryId]?.length) {
        commit('SET_CATEGORY_ID', categoryId)
        return
      }

      const today = new Date().toISOString().slice(0, 10)
      
      const baseFilters = [
        { key: 'category_ids', value: { eq: categoryId } },
        { key: 'is_featured_product', value: { eq: true } },
        { key: 'stock.is_in_stock', value: { eq: true } }
      ]

      const dateRangeFilters = [
        { key: 'news_from_date', value: { lte: today } },
        { key: 'news_to_date', value: { gte: today } }
      ]

      // Try to get new products first
      const newProductsQuery = prepareQuery({ 
        filters: [...baseFilters, ...dateRangeFilters] 
      })

      const newProducts = await dispatch('product/findProducts', {
        query: newProductsQuery,
        size: PRODUCTS_LIMIT,
        sort: 'news_from_date:desc',
        options: QUERY_OPTIONS
      }, { root: true })

      let allProducts = [...(newProducts?.items || [])]

      // If we need more products, get regular featured products
      if (allProducts.length < PRODUCTS_LIMIT) {
        const remainingCount = PRODUCTS_LIMIT - allProducts.length
        const regularQuery = prepareQuery({ filters: baseFilters })
        
        const regularProducts = await dispatch('product/findProducts', {
          query: regularQuery,
          size: remainingCount,
          options: QUERY_OPTIONS
        }, { root: true })

        allProducts = [...allProducts, ...(regularProducts?.items || [])]
      }

      // Remove duplicates
      const uniqueProducts = Array.from(
        new Map(allProducts.map(product => [product.id, product])).values()
      )

      commit('SET_CATEGORY_ID', categoryId)
      commit('ADD_CATEGORY_PRODUCTS', { categoryId, products: uniqueProducts })
    }
  },

  mutations: {
    ADD_CATEGORY_PRODUCTS(state, { categoryId, products }) {
      state.productsByCategory = {
        ...state.productsByCategory,
        [categoryId]: products || []
      }
    },
    SET_CATEGORY_ID(state, categoryId) {
      state.currentCategoryId = categoryId
    }
  },

  getters: {
    getFeaturedProducts: state => state.productsByCategory[state.currentCategoryId] || [],
    getCurrentCategoryId: state => state.currentCategoryId
  }
}