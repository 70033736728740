import { module } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory'

export const KEY = 'search-suggestions'

export const SearchSuggestions: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, module)

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('search_synonyms', {
      queryProcessor: (query) => {
        // function that can modify the query each time before it's being executed
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'search_synonyms', start, size)
      }
    })
  })
}
