import { SearchQuery } from 'storefront-query-builder'

export const createLoadSynonymsQuery = ({ searchString }) => {
  let query = new SearchQuery()

  if (searchString) {
    query = query.applyFilter({
      key: 'synonyms',
      value: { [Array.isArray(searchString) ? 'in' : 'eq']: searchString }
    })
  }

  return query
}
