import { SearchQuery } from 'storefront-query-builder'

class CustomSearchQuery extends SearchQuery {
  private _synonymMode: boolean

  public constructor (queryObj?: any, synonymMode: boolean = false) {
    super(queryObj) // Call parent constructor
    this._synonymMode = synonymMode
  }

  /**
   * Get synonym mode
   * @return {boolean}
   */
  public getSynonymMode (): boolean {
    return this._synonymMode
  }

  /**
   * Set synonym mode
   * @param {boolean} synonymMode
   * @return {this}
   */
  public setSynonymMode (synonymMode: boolean): this {
    this._synonymMode = synonymMode
    return this
  }
}

export default CustomSearchQuery
